import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AuthService } from '@app/auth.service';
import { environment } from '@environments/environment';
import { tap } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatButtonModule, MatCardModule],
  providers: [AuthService, HttpClient],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  private http = inject(HttpClient);

  login(e: MouseEvent) {
    e?.stopPropagation();
    const url = new URL(`${environment.api.kepler.mindpalace.url}/saml/login`);
    url.searchParams.set('returnUrl', window.location.hostname + '/login');
    this.http
      .get<string>(url.toString(), {
        headers: {
          'X-Api-Key': environment.api.kepler.mindpalace.apiKey,
        },
      })
      .pipe(
        tap((res) => {
          console.log('kepler res', res);
          window.location.href = res;
        }),
      )
      .subscribe();
  }
}
