export const environment = {
  name: 'dev',
  production: false,
  appBaseHref: '/',
  api: {
    kepler: {
      mindpalace: {
        apiKey: '27OB3uY0w15RGOokbz3S93MNCUz7dgvAsxUrV69c',
        url: 'https://kepler-mindpalace-api.genesyscsdteng.com/know2605',
      },
    },
  },
};
