<header>
  <nav>
    <a mat-fab extended routerLink="home"><mat-icon>home</mat-icon>Home</a>
    <a mat-fab extended routerLink="public"><mat-icon>public</mat-icon>Public</a>
    <a mat-fab extended routerLink="protected">
      <mat-icon>{{ (isAuthed$ | async) == true ? 'lock_open' : 'lock' }}</mat-icon>
      Protected
    </a>
  </nav>
</header>
<main>
  <router-outlet></router-outlet>
</main>
