<h1>Welcome</h1>
<p>This page initiates the SAML login flow.</p>
<div>
  <mat-card>
    <mat-card-header>
      <img mat-card-avatar src="/sfdc.png" />
      <mat-card-title>Kepler+Salesforce Auth</mat-card-title>
      <mat-card-subtitle>SAML Proof-of-Concept</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <button mat-flat-button color="primary" (click)="login($event)"> Login </button>
    </mat-card-content>
  </mat-card>
</div>
